export const NairaSymbol = '₦';

export const FulfilmentService = [
  {
    key: 'Telemedicine',
    value: 'Telemedicine',
  },
  {
    key: 'Acute',
    value: 'Acute',
  },
  {
    key: 'Chronic',
    value: 'Chronic',
  },
];

export const Gender = [
  {
    key: 0,
    value: 'Female',
    text: 'Female',
  },
  {
    key: 1,
    value: 'Male',
    text: 'Male',
  },
  // {
  //   key: 2,
  //   value: "Others",
  //   text: "Others",
  // },
];

export const SettingsTabs = {
  Profile: 'profile',
  ApiCredentials: 'apiCredentials',
  Account: 'account',
};

export const RefillStatus = {
  Pending: 'Pending',
  Approved: 'Approved',
  Declined: 'Declined',
  Processed: 'Processed',
  UnProcessed: 'UnProcessed',
};

export const RefillView = [
  {
    value: 'All',
    text: 'All',
  },
  {
    value: 'DueToday',
    text: 'Due Today',
  },
  {
    value: 'DueInSevenDays',
    text: 'Due In 7 Days',
  },
  {
    value: 'DueInOneMonth',
    text: 'Due In One Month',
  },
  {
    value: 'Custom',
    text: 'Custom Date',
  },
];
