import { useEffect, useState } from 'react';
import { IFulfilmentResponse } from '../../interfaces/FulfilmentInterfaces';
import { IPharmacyList } from '../../interfaces/PharmacyInterface';

const FulfilmentSideModal = ({
  showModal,
  modalData,
  close,
}: {
  showModal: boolean;
  modalData: IFulfilmentResponse | undefined;
  close: Function;
}) => {
  const [pharmacyInfo, setPharmacyInfo] = useState({
    pharmacyName: '',
    pharmacyAddress: '....',
  });

  useEffect(() => {
    //get pharmacy info

    if (showModal) {
      const savedPharmacyList = sessionStorage.getItem('pharmacy-list');

      if (!savedPharmacyList || savedPharmacyList === null) {
        return;
      }

      const parsedList = JSON.parse(savedPharmacyList) as IPharmacyList[];
      const selectedPharmacy = parsedList.filter((x) => x.pharmacyCode === modalData?.pharmacyCode);
      if (selectedPharmacy.length === 0) {
        return;
      }
      const found = selectedPharmacy[0];
      setPharmacyInfo((f) => {
        return {
          ...f,
          pharmacyAddress: found.address,
          pharmacyName: found.pharmacyName,
        };
      });
    }
  }, [modalData, showModal]);

  const formatFulfilmentStatus = (fulfilmentStatus: string) => {
    switch (fulfilmentStatus) {
      case 'Dispensed':
      case 'Approved':
      case 'Completed':
        return 'Dispensed';
      default:
        return fulfilmentStatus;
    }
  };

  const getStatusColor = (fulfilmentStatus: string) => {
    switch (fulfilmentStatus) {
      case 'Dispensed':
      case 'Approved':
      case 'Completed':
        return 'btn-success';
      case 'Cancelled':
        return 'btn-danger';
      default:
        return 'btn-info';
    }
  };

  return (
    <div
      id='sidebarMenu'
      className={
        showModal
          ? 'col-md-6 col-lg-4 right-sidebar sideshow'
          : 'col-md-6 col-lg-4 right-sidebar sidehide'
      }
    >
      <div className='right-sidebar-sticky pt-3 mt-2 bg-green-light'>
        <div>
          <span
            className='btn btn-sm fs-8'
            style={{ marginLeft: '-10px' }}
            onClick={() => close('Info')}
          >
            Close (x)
          </span>
        </div>
        {modalData ? (
          <div>
            <h5 className='text-center mt-2 fw-bold '>
              {modalData.enrolleeFirstName} {modalData.enrolleeLastName} ({modalData.enrollmentCode}
              )
            </h5>
            <div className='mt-3'>
              <span className='btn-secondary p-1 btn-sm'>{modalData.fulfilmentService}</span>
              <span
                className={
                  'ml-3 btn-success p-1 btn-sm ' + getStatusColor(modalData.fulfilmentStatus)
                }
              >
                {formatFulfilmentStatus(modalData.fulfilmentStatus)}
              </span>
            </div>
            <div className='mt-4'>
              <label className='fs-7 fw-light'>Phone number</label>
              <div className=''>{modalData.enrolleePhone}</div>
            </div>
            <div className='mt-4'>
              <label className='fs-7 fw-light'>Address</label>
              <div className=''>{modalData.enrolleeAddress}</div>
            </div>
            <div className='mt-4'>
              <label className='fs-7 fw-light'>Pharmacy</label>
              <div className='fw-bold'>{pharmacyInfo.pharmacyName}</div>
              <div className='mt-2'>{pharmacyInfo.pharmacyAddress}</div>
            </div>

            <div className='mt-4'>
              <label className='fs-7 fw-light'>Medications Dispensed</label>
              <table className='table table-borderless custom-table'>
                <tbody>
                  {modalData.drugs.map((drug) => (
                    <tr className='custom-tr mt-3'>
                      <td>{drug}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default FulfilmentSideModal;
