import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import DataOverview from './DataOverview/DataOverview';
import {
  defaultHmoSummary,
  IEnrolleeSummary,
  IHmoSummary,
} from '../../interfaces/UtilizationInterfaces';
import { endpoints } from '../../utils/URLs';
import axios from 'axios';
import { formatDateTime, validateDateRange } from '../../utils/dateTimeUtils';
import { NairaSymbol } from '../../utils/mappings';
import ButtonLoader from '../common/ButtonLoader';
import { AppContext } from '../../context/AppContext';
import { useHistory } from 'react-router-dom';
import { addToast } from '../../utils/toastNotifications';

const ClaimUtilization = () => {
  const [dataSummary, setDataSummary] = useState<IHmoSummary | undefined>(defaultHmoSummary);
  const [searchResult, setSearchResult] = useState<IEnrolleeSummary[]>([]);
  const [searchParam, setSearchParam] = useState({
    searchTerm: '',
    startDate: '',
    endDate: '',
  });
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(AppContext);
  const history = useHistory();

  const handleSearch = (input: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchParam({
      ...searchParam,
      [input]: value,
    });
  };

  const viewEnrolleeInfo = (enrolleeInfo: IEnrolleeSummary) => (event: any) => {
    event.preventDefault();
    dispatch({
      type: 'SET_ENROLLEE',
      payload: enrolleeInfo,
    });
    history.push(`/utilization/view?hmoId=${enrolleeInfo.enrolleeId}`);
  };

  const fetchHmoUtilizationSummary = useCallback(async (startDate?: string, endDate?: string) => {
    setIsLoading(true);
    try {
      let url = `${endpoints.utilizationReport.mainUrl}/?`;

      if (startDate && startDate !== '') {
        url += `startDate=${startDate}&`;
      }
      if (endDate && endDate !== '') {
        url += `endDate=${endDate}&`;
      }

      const response = await axios.get(url);
      setDataSummary(response.data);
    } catch (error) {
      console.error(error);
      addToast('An error occurred while fetching utilization data', 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onFilterClick = async () => {
    const validationResult = validateDateRange(searchParam.startDate, searchParam.endDate);

    if (!validationResult.isValid) {
      addToast(validationResult.msg, 'error');
      return;
    }

    await fetchHmoUtilizationSummary(searchParam.startDate, searchParam.endDate);
  };

  const search = async (event: FormEvent) => {
    event.preventDefault();
    setIsSearching(true);
    try {
      const url = `${endpoints.utilizationReport.mainUrl}/search?searchQuery=${searchParam.searchTerm}`;
      const response = await axios.get(url);
      setSearchResult(response.data);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    fetchHmoUtilizationSummary();
  }, [fetchHmoUtilizationSummary]);

  return (
    <div className='container-fluid'>
      {/* Filter Component */}
      <div className='card mt-3 mb-4'>
        <div className='card-body py-2'>
          <div className='row align-items-center'>
            <div className='col-md-3 col-sm-6 mb-2 mb-md-0'>
              <input
                type='date'
                className='form-control form-control-sm'
                value={searchParam.startDate}
                onChange={handleSearch('startDate')}
                placeholder='Start Date'
              />
            </div>
            <div className='col-md-3 col-sm-6 mb-2 mb-md-0'>
              <input
                type='date'
                className='form-control form-control-sm'
                value={searchParam.endDate}
                onChange={handleSearch('endDate')}
                placeholder='End Date'
              />
            </div>
            <div className='col-md-2 col-sm-12'>
              <button
                className='btn btn-primary btn-sm w-100'
                onClick={onFilterClick}
                disabled={isLoading}
              >
                Filter {isLoading && <ButtonLoader />}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Dashboard */}
      {dataSummary && <DataOverview summary={dataSummary} />}

      {/* Search component */}
      <div className='card mt-5 pt-4 pb-3'>
        <div className='card-body'>
          <div className='row align-items-end'>
            <div className='col-lg-5 col-sm-8 mt-3'>
              <input
                type='text'
                placeholder='Enrollee ID/Phone number'
                className='form-control'
                value={searchParam.searchTerm}
                onChange={handleSearch('searchTerm')}
              />
            </div>
            <div className='col-lg-2 col-sm-4 mt-3'>
              <button className='btn btn-primary btn-block px-3' onClick={search}>
                Search {isSearching && <ButtonLoader />}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className='table-responsive mt-4'>
        <table className='table table-hover table-borderless custom-table'>
          <thead>
            <tr>
              <th>Action</th>
              <th>Date</th>
              <th>Name</th>
              <th>Enrollee ID</th>
              <th>Total Cost</th>
              <th>Encounter Count</th>
            </tr>
          </thead>
          <tbody>
            {searchResult.map((item, index) => (
              <tr key={index} className='custom-tr'>
                <td>
                  <button
                    type='button'
                    className='btn btn-sm btn-success'
                    onClick={viewEnrolleeInfo(item)}
                  >
                    View <i className='bi bi-eye'></i>
                  </button>
                </td>
                <td>{formatDateTime(item.dateCreated)}</td>
                <td>{item.enrolleeName}</td>
                <td>{item.enrolleeId}</td>
                <td>
                  {NairaSymbol}
                  {item.totalCost.toLocaleString()}
                </td>
                <td>{item.totalFulfilments.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClaimUtilization;
